@import 'utils', 'header', 'footer', 'components', 'page', 'bootstrap', 'button';
@import "~react-image-gallery/styles/scss/image-gallery.scss";
// @import '~react-bootstrap-sidebar-menu/dist/sidebar-menu.scss';

body {
  font-family: 'IBM Plex Sans Thai', sans-serif;
  font-weight: 400;
  background-color: $color-white;
}

a {
  color: $color-black;
  text-decoration: none;
  font-weight: 500;
}

a:hover {
  color: $color-green;
  font-weight: 500;
}

hr {
  border: $line-gold
}

.main-content {
  @include fontSizeChange(1rem);
  @include imgColorMode();
}

.color-mode-0 {
  // color: $color-black;
}

.color-mode-1 {
  // filter ทั้งเว็บได้เลยไหม
  color: $color-white;
  background-color: $color-black;
}

.color-mode-2 {
  color: $color-yellow-yellow;
  background-color: $color-black;
}

.text-align-right {
  text-align: right;
}


.text-white {
  color: $color-white;
}

.text-normal-grey {
  color: $color-normal-grey;
}

.text-brown {
  color: $color-brown;
}

.floating-icon {
  border-radius: 50px;
  background-color: #ffffff;
  box-shadow: $shadow-main
}
// .floating-icon {
//   position: fixed;
//   width: 60px;
//   height: 60px;
//   bottom: 40px;
//   right: 40px;
//   background-color: #ffffff;
//   color: #FFF;
//   border-radius: 50px;
//   text-align: center;
//   box-shadow: $shadow-main
// }

// .float-obj {
//   width: 100%;
// }