.footer {
    background-color: $color-green;
    background-image: url(../assets/images/footer_rice_bg.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center top;
    color: $color-white;
    margin-top: 1rem;

    .footer-box {
        height: auto;
        padding-top: 15px;
        padding-bottom: 5px;

        .footer-header-text {
            margin-top: .5rem;
            margin-bottom: .2rem;
            @include fontSizeChange(1.2rem);
            font-weight: 500;
            color: $color-white;
        }

        a {
            color: $color-white;
            text-decoration: none;

            /* Hover effect: Change the color to green */
            &:hover {
                color: yellow;
            }
        }


        .footer-logo-text {
            @include fontSizeChange(1.5rem);
            font-weight: 400;
            color: $color-white;
        }
    }
}

.footer-bottom-bar {
    background-color: $color-green;

    .footer-bottom-bar-text {
        height: auto;
        padding-top: 15px;
        padding-bottom: 5px;
        text-align: center;
        color: $color-white;

        .footer-bottom-bar-text-mini {
            @include fontSizeChange(.8rem);
        }
    }
}

.text-cookie,
.text-cookie:hover {
    color: $color-gold;
    text-decoration: underline;
}

.ita-box {
    text-align: center;
    background-color: $color-white;
    border-radius: $border-radius-main;
    color: $color-black !important;
    padding: 0.2rem;
    @include fontSizeChange(.8rem);
}

.ita-box-font {
    color: $color-black !important;
}