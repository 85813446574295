.btn-primary,
.btn-primary:active,
.btn-primary:focus {
  color: $color-black;
  background-color: $color-gold;
  border-color: $color-gold;
}

.btn-primary:hover {
  color: $color-white;
  background-color: $color-brown;
  border-color: $color-brown;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-toggler {
  background-color: $color-transparent;
}

.navbar-light .navbar-toggler {
  border: none;
}

.navbar-light .navbar-nav .nav-link {
  border-radius: 0;
  color: $color-brown;
  font-weight: 500;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs .nav-link {
  border-radius: 0 !important;
  border-bottom-color: $color-green;
  color: $color-brown;
  @include fontSizeChange(0.9rem);
}

.nav-link.active {
  border-radius: 0;
  border-bottom-color: $color-green !important;
  background-color: $color-green !important;
  color: $color-gold !important;
  font-weight: 600;
}

.nav-item.dropdown:hover .dropdown-menu {
  animation: slideshow 200ms;
  display: block;

}

.nav-link:hover {
  background-color: $color-green;
  font-weight: 500;
  color: $color-white !important;
}

.dropdown-menu {
  border: none;
  border-radius: 0;
}

.dropdown-menu[data-bs-popper] {
  margin-top: 0;
}

.carousel-item img {
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  height: 30vw;
}

offcanvas-header {
  border-bottom: none;
}

.offcanvas-body {
  padding: 10px;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0.5rem;
}

.header-offcanvas-nav {
  .nav-link {
    padding-left: 1;
    color: $color-brown;
    font-weight: 500;

    &.active {
      background-color: $color-green;
      color: $color-gold;
      font-weight: 600;
    }

    &:hover {
      background-color: $color-green;
      color: $color-white;
    }
  }
}

.close-icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #333;
  /* Customize the color of the "X" icon */
  transition: color 0.3s ease;
  /* Add a transition effect for hover */

  &:hover {
    color: #ff0000;
    /* Customize the color on hover */
  }
}

.page-item.active .page-link {
  background-color: $color-green !important;
  border: $line-gold !important;
  color: $color-white !important;
}

.page-link {
  color: $color-green !important;
}