.purple-gradient {
    background: linear-gradient(40deg, #ff6ec4, #7873f5) !important
}

.peach-gradient {
    background: linear-gradient(40deg, #ffd86f, #fc6262) !important
}

.aqua-gradient {
    background: linear-gradient(40deg, #2096ff, #05ffa3) !important
}

.blue-gradient {
    background: linear-gradient(40deg, #45cafc, #303f9f) !important
}

.purple-gradient-rgba {
    background: linear-gradient(40deg, rgba(255, 110, 196, 0.9), rgba(120, 115, 245, 0.9)) !important
}

.peach-gradient-rgba {
    background: linear-gradient(40deg, rgba(255, 216, 111, 0.9), rgba(252, 98, 98, 0.9)) !important
}

.aqua-gradient-rgba {
    background: linear-gradient(40deg, rgba(32, 150, 255, 0.9), rgba(5, 255, 163, 0.9)) !important
}

.blue-gradient-rgba {
    background: linear-gradient(40deg, rgba(69, 202, 252, 0.9), rgba(48, 63, 159, 0.9)) !important
}