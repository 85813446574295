.news-section-box {
    padding-top: 30px;
    padding-bottom: 30px;
}

.news-section-header-box {
    margin-bottom: 1.5rem;
}

.news-img-card {
    transition: $normal-easing;
    border-radius: $border-radius-main;
    width: 100%;
    box-shadow: $shadow-main;
}

.news-img-card:hover {
    transform: scale(1.03);
}

.news-section-header-with-line {
    @include fontSizeChange(1.5rem);
    border-left: 9px solid $color-green;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
}

.news-section-header-with-line::after {
    content: '';
    flex: 1 1;
    border-bottom: $line-gold;
    margin: auto;
}

.news-card-section {
    margin-top: 10px;
}

.news-thumbnail {
    transition: $normal-easing;

    .news-thumbnail-box {
        margin-top: 20px;
    }

    .news-thumbnail-headline {
        @include fontSizeChange(1.2rem);
        font-weight: 500;
        @include util-cut-text;
    }

    .news-thumbnail-description {
        font-family: 'Bai Jamjuree';
        margin-top: -10px;
        @include fontSizeChange(.8rem);
        @include util-cut-text-multi;
    }

    .news-thumbnail-attribute {
        margin-top: -10px;
        font-weight: 200;
        color: $color-normal-grey;
        @include fontSizeChange(.7rem);
    }
}

.news-thumbnail:hover {
    transform: scale(1.03);
}

.pr-news-thumbnail-headline {
    border-left: 3px solid $color-gold;
    padding-left: 4px;
    @include fontSizeChange(.95rem);
    font-weight: 500;
    @include util-cut-text;
}

.pr-news-thumbnail-description {
    font-family: 'Bai Jamjuree';
    margin-top: -10px;
    @include fontSizeChange(.8rem);
    @include util-cut-text-multi;
}

.pr-news-thumbnail-attribute {
    margin-top: -10px;
    font-weight: 200;
    color: $color-normal-grey;
    @include fontSizeChange(.7rem);
}

.center-section-header {
    @include fontSizeChange(1.5rem);
    text-align: center;
    display: flex;
    flex-direction: row;
}

.center-section-header::after,
.center-section-header::before {
    content: '';
    flex: 1 1;
    border-bottom: $line-gold;
    margin: auto;
}

.carousel-top-fade {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    height: 2vw;
}

.menu-card-name {
    @include fontSizeChange(1.2rem);
    text-align: center;
    font-weight: 600;
}

.banner-circle-box {
    margin-top: 1.2rem;
}

.banner-circle-text {
    @include fontSizeChange(1rem);
    font-weight: 600;
    padding: 1.2rem;
}

.banner-circle-img {
    border-radius: 50%;
    width: 100%;
}

.infographic {
    text-align: center;
}

.infographic-img {
    display: inline-block !important;
    width: 80% !important;
    height: auto !important;
}

.link-img-card {
    margin-top: 1rem;
    transition: $normal-easing;
    border-radius: $border-radius-main;
    width: 100%;
    box-shadow: $shadow-main;
}

.link-img-card:hover {
    transform: scale(1.03);
}

// .entersite-box {
//     padding: 18rem;
// }

.entersite-img {
    transition: $normal-easing;
    border-radius: $border-radius-main;
    width: 100%;
    box-shadow: $shadow-main;
}

.personnel-section {
    text-align: center;
    margin-bottom: 1rem;
}

.personnel-thumbnail-name {
    @include fontSizeChange(1.2rem);
    font-weight: 500;
}

.personnel-thumbnail-position {
    font-family: 'Bai Jamjuree';
    margin-top: -10px;
    @include fontSizeChange(1.1rem);
}

.all-link {
    padding: 1rem;
    height: 100%;
}

.all-link-box-1 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gold;
    @include fontSizeChange(1.1rem);
    font-weight: 600;
    padding: 1rem;
    box-shadow: $shadow-main;
    border-radius: $border-radius-main;
    background-image: url(../assets/images/header-bg-1.jpg);
    @include header-main;
    height: 100% !important;
}

.all-link-box-2 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gold;
    @include fontSizeChange(1.1rem);
    font-weight: 600;
    padding: 1rem;
    box-shadow: $shadow-main;
    border-radius: $border-radius-main;
    background-image: url(../assets/images/header-bg-2.jpg);
    @include header-main;
    height: 100% !important;
}

.all-link-box-3 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gold;
    @include fontSizeChange(1.1rem);
    font-weight: 600;
    padding: 1rem;
    box-shadow: $shadow-main;
    border-radius: $border-radius-main;
    background-image: url(../assets/images/header-bg-3.jpg);
    @include header-main;
    height: 100% !important;
}

.paginationBox{
    text-align: right;
}