.header-title-text {
    @include fontSizeChange(1.8rem);
    font-weight: 500;
    color: $color-black;
    line-height: 2rem;
    margin-top: 15px;
}

.header-sub-text {
    @include fontSizeChange(1.1rem);
    font-weight: 500;
    color: $color-brown;
    line-height: 1.4rem;
    margin-top: 5px;
}


.header-top-bar {

    .header-top-bar-box {
        background-color: $color-yellow-alpha;
        height: auto;
        padding-top: 2px;
        padding-bottom: 2px;
        box-shadow: $shadow-main;
        border-bottom-left-radius: $border-radius-main;
        border-bottom-right-radius: $border-radius-main;
    }

    .header-top-bar-blind-icon {
        width: 20px;
        margin-right: 3px;
    }

    .header-top-bar-menu {
        text-align: center;
    }
}

.header-site-group-0 {
    background-color: $color-white;
    @include header-main;
}

.header-site-group-1 {
    background-color: $color-yellow;
    background-image: url(../assets/images/header-bg-1.jpg);
    @include header-main;
}

.header-site-group-2 {
    background-color: $color-yellow;
    background-image: url(../assets/images/header-bg-2.jpg);
    @include header-main;
}

.header-site-group-3 {
    background-color: $color-yellow;
    background-image: url(../assets/images/header-bg-3.jpg);
    @include header-main;
}

.header-site-group-4 {
    background-color: $color-yellow;
    background-image: url(../assets/images/header-bg-4.jpg);
    @include header-main;
}

.header-logo-box {
    height: auto;
    padding-bottom: 1rem;
    position: relative;
    // top: -1.4rem;

    .header-logo-img {
        width: 100%;
    }
}

.header-hamburger {
    display: flex;
    justify-content: flex-end;
}

.header-rice-nav-menu {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    background-color: $color-white;
    position: relative;
    top: calc(var(--scroll-direction) * -1rem);
    z-index: -10000;
}

.header-search {
    background-color: $color-light-grey;

    .header-search-box {
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;

        .header-search-box-icon {
            text-align: right;
            padding: 5px;
        }
    }
}

@media (max-width: 576px) {
    .header-title-text {
        @include fontSizeChange(1.4rem);
        font-weight: 600;
        color: $color-black;
        line-height: 1.8rem;
        margin-top: 15px;
    }

    .header-sub-text {
        @include fontSizeChange(.9rem);
        font-weight: 500;
        color: $color-black;
        line-height: 1.2rem;
        margin-top: 5px;
    }

}

@media (max-width: 992px) {
    .header-rice-navbar {
        background-color: $color-transparent;
        border: none;
    }

    .header-logo-box {
        top: 0rem;
    }
}


.side-menu {
    padding: 1rem;
}

.nav-link {
    color: $color-brown;
}

.sidebar-title-text {
    color: $color-green;
    font-size: 1.4rem;
    font-weight: 500;
}