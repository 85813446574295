.breadcrumb-box-page-free {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    background-image: url(../assets/images/page_free_bc.png);
}

.breadcrumb-box-news-royal {
    padding-top: 1.3rem;
    padding-bottom: 8rem;
    background-image: url(../assets/images/news_royal_bc.png);
}

.breadcrumb-box-news-pr {
    padding-top: 1.3rem;
    padding-bottom: 8rem;
    background-image: url(../assets/images/news_royal_bc.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.breadcrumb-text-box {
    padding: 1rem;
    background-color: $color-white-alpha;
}

.news-page-header {
    position: relative;
    top: 0rem;
    padding-top: 1rem;
    padding-bottom: 1.3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    @include fontSizeChange(1.4rem);
    font-weight: 500;
}

.news-gallery-box {
    position: relative;
    top: -5rem;
}

.news-page-short-desc {
    @include fontSizeChange(1.1rem);
    font-weight: 400;
}

.news-page-content {
    position: relative;
    // top: -5rem;
    font-family: 'Bai Jamjuree';
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    @include fontSizeChange(.9rem);
    font-weight: 400;
}

.page-header-box {
    margin-top: 2rem;
}

.page-content {
    position: relative;
    font-family: 'Bai Jamjuree';
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    @include fontSizeChange(.9rem);
    font-weight: 400;
}

.doc-download {
    padding: 1rem;
    margin-bottom: 1rem;
    border: $line-gold;
    border-radius: $border-radius-main;
}

// Box in e-services
.category-services-box {
    text-align: center;
    padding: .5rem;
    margin-bottom: 1rem;
    border: $line-gold;
    border-radius: $border-radius-main;
}

.search-result-box {
    height: 300px;
    overflow-y: auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 6rem;
    margin-right: 3rem;
}

.section-box {
    margin-top: 2.5rem;
}

@media (max-width: 992px) {
    .news-page-content {
        top: -3rem;
    }

    .news-page-header {
        top: -3rem;
    }
}

.page404-box {
    height: 200px;
    text-align: center;
    padding: 3rem;
}