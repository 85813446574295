// VALUES
$font-ratio : 0.1rem;
$border-radius-main: 6px;

// COLORS
$color-black: #000000;
$color-dark-grey: #2c3e50;
$color-normal-grey: #95a5a6;
$color-light-grey: #ecf0f1;
$color-white: #ffffff;
$color-gold: #e9d189;
$color-brown: #7D5714;
$color-yellow: #E9B621;
$color-yellow-yellow: yellow;
$color-green: #129137;
$color-white-alpha: rgba(255, 255, 255, .6);
$color-yellow-alpha: #E9B62195;
$color-transparent: rgba(255, 255, 255, 0);

// COMPONENTS
$line-gold : 1px solid $color-gold;
$line-yellow-yellow : 1px solid yellow;
$shadow-main: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 $border-radius-main 20px 0 rgba(0, 0, 0, 0.19);
$shadow-gold: 0 1px 2px 0 #e9d18920, 0 $border-radius-main 20px 0 #e9d18919;

// ANIMATIONS
$normal-easing: all .2s ease-in-out;

@keyframes slideaway {
    from {
        display: block;
    }

    to {
        transform: translateY(40px);
        opacity: 0;
    }
}

@keyframes slideshow {
    from {
        display: none;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
    }
}

@function fontSizeCalculate($default-font-size) {
    @return calc((var(--font-size-val) * $font-ratio) + $default-font-size);
}

@mixin fontSizeChange($default-font-size) {
    font-size: fontSizeCalculate($default-font-size);
    transition: $normal-easing;
}

@mixin header-main{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: auto;
    box-shadow: $shadow-main;
}

@mixin util-cut-text {
    text-decoration: none;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

@mixin util-cut-text-multi {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin colorMode2() {
    color: $color-yellow-yellow;
}

@mixin imgColorMode () {
    $mode: var(--color-mode-val);

    img {
        filter: grayscale($mode);
    }

    // @if $mode ==0 {
    //     img {
    //         filter: grayscale(0);
    //     }
    // }

    // @else if $mode ==1 {
    //     img {
    //         filter: grayscale(1);
    //     }
    // }

    // @else if $mode ==2 {
    //     img {
    //         filter: grayscale(1);
    //     }
    // }
}